<template>
  <div class="col main_topics_wrapper_container">
    <!-- Topic List  -->

    <!-- LArge Screens  -->
    <server-table
      class="hidden-sm-only hidden-xs-only"
      :count="$store.getters['topics/totalTopics']"
      :DataItems="totalTopics"
      @Refresh="Refresh"
      @export="Export"
      @reset="filter = {}"
      :filter="filter"
      import_url="topics/import"
      :loading="$store.getters['topics/usersLoad']"
      :title="`${localization('Topics')}`"
      admin_permission="admin_topics"
      edit_permission="add_topics"
    >
      <template #columns>
        <el-table-column prop="id" label="#" width="100"></el-table-column>
        <el-table-column
          prop="name"
          sortable
          :label="`${localization('Name')}`"
          min-width="150"
        ></el-table-column>
        <!-- Task Type  -->
        <el-table-column prop="type" label="type" min-width="100">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.type_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="mode_text" label="mode" min-width="100">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.mode_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Language"
          :label="`${localization('Language')}`"
          sortable
          width="120"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ language(languages, scope.row.language) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="removed"
          sortable
          :label="`${localization('State')}`"
          width="120"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
            >
              {{
                scope.row.removed == 0
                  ? `${localization("Active")}`
                  : `${localization("Inactive")}`
              }} </l-button
            >&nbsp;
          </template>
        </el-table-column>

        <el-table-column prop="" label="_" min-width="160">
          <template slot-scope="scope">
            <div class="td-actions">
              <router-link
                v-if="hasPermission('show_topics')"
                :to="`/topic/${scope.row.id}`"
                v-tooltip.top-center="`${localization('Show Topic')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link>
              <a
                v-if="hasPermission('edit_topics')"
                v-tooltip.top-center="`${localization('Edit Topic')}`"
                class="btn btn-warning btn-link btn-xs"
                @click="open_topic_dialog(2, scope.row)"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-if="hasPermission('edit_topics')"
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete Topic')}`
                    : `${localization('Active Topic')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="10">
          <el-col :md="12"
            ><el-select
              v-model="filter.type"
              clearable
              placeholder="Type"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in wall"
                :key="index"
                :label="item.label"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="12"
            ><el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option></el-select
          ></el-col>
          <el-col :md="12"
            ><el-select
              v-model="filter.mode"
              placeholder="Mode"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in modeTypes"
                :key="item.id"
                :label="item.mode"
                :value="item.id"
              ></el-option></el-select
          ></el-col>
        </el-row>
      </template>
      <template #action>
        <div type="info" class="ml-2" @click="open_topic_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Topic") }}
        </div>
      </template>
    </server-table>
    <!-- //? LArge Screens  -->
    <server-table
      class="hidden-md-only hidden-lg-only hidden-xl-only"
      :count="$store.getters['topics/totalTopics']"
      :DataItems="$store.getters['topics/topics']"
      @Refresh="Refresh"
      @export="Export"
      @reset="filter = {}"
      :filter="filter"
      import_url="topics/import"
      :loading="$store.getters['topics/usersLoad']"
      :title="`${localization('Topics')}`"
      admin_permission="admin_topics"
      edit_permission="add_topics"
    >
      <template #columns>
        <el-table-column prop="id" label="#" width="70"></el-table-column>
        <el-table-column
          prop="name"
          :label="`${localization('Name')}`"
          sortable
        >
          <template slot-scope="scope">
            <div
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-sm-between
                align-items-sm-center
              "
            >
              {{ scope.row.name }}
              <div class="d-flex justify-content-between">
                <router-link
                  v-if="hasPermission('show_topics')"
                  :to="`/topic/${scope.row.id}`"
                  v-tooltip.top-center="`${localization('Show Topic')}`"
                  class=" btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
                <a
                  v-if="hasPermission('edit_topics')"
                  v-tooltip.top-center="`${localization('Edit Topic')}`"
                  class=" btn-warning btn-link btn-xs"
                  @click="open_topic_dialog(2, scope.row)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  v-if="hasPermission('edit_topics')"
                  v-tooltip.top-center="
                    scope.row.removed == 0
                      ? `${localization('Delete Topic')}`
                      : `${localization('Active Topic')}`
                  "
                  @click="remove(scope.row.id, scope.row.removed)"
                  :class="
                    scope.row.removed == 0
                      ? ` btn-danger btn-link btn-xs`
                      : ` btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                  <i v-else class="fa fa-check"></i>
                </a>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="10">
          <el-col :md="12"
            ><el-select
              v-model="filter.type"
              clearable
              placeholder="Type"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in wall"
                :key="index"
                :label="item.label"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="12"
            ><el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="12"
            ><el-select
              v-model="filter.mode"
              placeholder="Mode"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in modeTypes"
                :key="item.id"
                :label="item.mode"
                :value="item.id"
              ></el-option></el-select
          ></el-col>
        </el-row>
      </template>
      <template #action>
        <div type="info" class="ml-2" @click="open_topic_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Topic") }}
        </div>
      </template>
    </server-table>

    <!-- //? Add or Edit Topic  -->
    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Topic')}`
          : `${localization('Edit Topic')}`
      "
      width="60%"
      :visible.sync="topic_dialog"
      top="4vh"
      class="add_topics_dialog"
    >
      <el-form
        :model="topic"
        ref="ruleForm"
        :rules="rules"
        label-position="top"
      >
        <el-form-item
          :label="`${localization('Name')}`"
          label-width="100px"
          prop="name"
        >
          <el-input
            v-model="topic.name"
            autocomplete="off"
            style="width: 100%"
            placeholder="Name"
          ></el-input>
        </el-form-item>
        <!-- Mode -->
        <el-form-item label="Mode" label-width="100px" prop="mode">
          <el-select
            v-model="topic.mode"
            clearable
            placeholder="Mode"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in modeTypes"
              :key="index"
              :label="item.mode"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- Start Type -->
        <!-- if type in school or at work -->
        <el-form-item
          v-if="topic.mode == 1 || topic.mode == 2"
          label="Type"
          label-width="100px"
          prop="category_id"
        >
          <el-select
            v-model="topic.type"
            clearable
            placeholder=" Type"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in topicTypesStandard"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- if type just career -->
        <el-form-item
          v-if="topic.mode == 3"
          label="Type"
          label-width="100px"
          prop="category_id"
        >
          <el-select
            v-model="topic.type"
            clearable
            placeholder=" Type"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in topicTypesStandardCareer"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- End Type -->
        <!-- Task Category  -->
        <!-- [1] Category For [School] When Choose Special Default -->
        <el-form-item
          v-if="topic.type == 3 && topic.mode == 1"
          label="Task Category"
          label-width="100px"
          prop="category_id"
        >
          <el-select
            v-model="topic.category_id"
            clearable
            placeholder=" Task Category"
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="(item, index) in totalCategories"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- [2] Category For [At Work] When Choose Special Default -->
        <el-form-item
          v-if="topic.type == 3 && topic.mode == 2"
          label="Task Category"
          label-width="100px"
          prop="category_id"
        >
          <el-select
            v-model="topic.category_id"
            clearable
            placeholder=" Task Category"
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="(item, index) in atWorkCategories"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- [2] Category For [Career Match] When Choose Special Default -->
        <el-form-item
          v-if="topic.type == 3 && topic.mode == 3"
          label="Task Category"
          label-width="100px"
          prop="category_id"
        >
          <el-select
            v-model="topic.category_id"
            clearable
            placeholder=" Task Category"
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="(item, index) in careerMatchCategories"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="`${localization('Language')}`"
          label-width="100px"
          prop="language"
        >
          <el-select
            v-model="topic.language"
            :placeholder="`${localization('Please select language')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in languages"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="topic_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="text-center text-capitalize save_button "
          type=""
          @click="save"
          >{{ localization("Save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: { ServerTable },
  data() {
    return {
      topic_dialog: false,
      topic: {},
      type: null,
      languages: [],
      modeTypes: [
        {
          id: 1,
          mode: "In School"
        },
        {
          id: 2,
          mode: "At Work"
        },
        {
          id: 3,
          mode: "Career Match"
        }
      ],

      wall: [
        {
          id: 1,
          label: "Standard"
        },
        {
          id: 2,
          label: "Default "
        },
        {
          id: 3,
          label: "Special Default"
        }
      ],
      filter: {},
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        mode: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      localSort: { col: "id", order: "DESC" },
      // preserve sorting concept to local component
      // here for in school and at work
      topicTypesStandard: [
        {
          id: 1,
          name: "Standard"
        },
        {
          id: 2,
          name: "Default "
        },
        {
          id: 3,
          name: "Special Default"
        }
      ],
      // here for just career
      topicTypesStandardCareer: [
        {
          id: 1,
          name: "Standard"
        }
      ]
    };
  },

  computed: {
    totalCategories() {
      return this.$store.getters["topics/getTotalCategories"];
    },
    atWorkCategories() {
      return this.$store.getters["topics/getAtWorkCategories"];
    },
    careerMatchCategories() {
      return this.$store.getters["topics/getCareerMatchCategories"];
    },

    totalTopics() {
      return this.$store.getters["topics/topics"];
    }
  },
  mounted() {
    // getTotalCategories
    this.Refresh();
  },

  methods: {
    Refresh(query) {
      this.$store.dispatch("topics/GetTopics", { query: query }).then(_ => {
        this.languages = this.$store.getters["topics/languages"];
      });
    },
    remove(id, removed) {
      Swal.fire({
        title: "",
        text:
          removed == 0
            ? `Are you sure to delete this topic?`
            : "Are you sure to Active this topic?",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: removed == 0 ? "delete it" : "confirm",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("topics/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: removed == 0 ? `` : "",
              text:
                removed == 0
                  ? "Topic has been deleted."
                  : "Topic has been activated.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    },
    open_topic_dialog(type, value) {
      this.topic = { ...value };
      this.topic_dialog = true;
      this.type = type;
    },
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("topics/AddTopics", { query: this.topic })
              .then(_ => {
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.Refresh({});
                this.topic_dialog = false;
              });
          } else {
            this.$store
              .dispatch("topics/UpdateTopics", {
                id: this.topic.id,
                query: this.topic
              })
              .then(_ => {
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.Refresh({
                  limit: 4,
                  offset: 0,
                  order_by: this.localSort.col,
                  order_direction: this.localSort.order
                });
                this.topic_dialog = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    language(languages, id) {
      let language = languages && languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },
    Export(query) {
      this.$store.dispatch("topics/Export", { query: query });
    }
  }
};
</script>

<style scoped lang="scss">
.container--fluid {
  min-height: 80vh !important;
}
.add_topics_dialog {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    background-color: #23ccef;
    border-color: #23ccef;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>

<style lang="scss">
.main_topics_wrapper_container {
  .add_topics_dialog {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
</style>
